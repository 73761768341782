import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./post-mortems@gen.scss";

const GeneratedProductFeaturesPostMortems = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          事後検証から学び改善する - PagerDuty正規代理店 - 株式会社Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="サービスとプロセスを改善する"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="description" content="サービスとプロセスを改善する" />

        <meta
          property="og:title"
          content="事後検証から学び改善する - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="サービスとプロセスを改善する"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/post-mortems/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/post-mortems/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="事後検証から学び改善する"
        overview=""
      />

      <div className="pmWrapper">
        <div className="two-col-media-contents first-section">
          <div className="text-part">
            <p className="title">
              サービスとプロセスを
              <br />
              改善する
            </p>
            <p className="para">
              事後検証プロセスを合理化することで、インシデントを解決し予防する力を高めます。
              <br />
              関連するPagerDutyおよびチャットのアクティビティを利用してタイムラインを数分で作成し、その詳細な内訳を使用し、根本的な原因を効率的に調査したり、レスポンスの有効性を評価したり、優先度の高いフォローアップ事項を特定することができます。
              <br />
              継続的なフィードバックループを構築してチーム全体が学びを共有することで、組織として改善を慣行化することができます。
            </p>
          </div>
          <div className="media-contents-part">
            <img
              src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/post_mortems_42dd5ca28d.png"
              alt="サービスとプロセスを改善する"
            />
          </div>
        </div>
        {/* details */}
        <div className="center-1">
          <h2 className="center-title">事後検証の詳細</h2>
        </div>
        <div className="three-col-without-cta">
          <div className="card">
            <div className="title">
              <p>合理化された事後検証プロセス</p>
            </div>
            <p>
              インシデント事後検証レポートをワンクリックで作成可能です。オーナーが認識され、事後検証の状態がわかりやすく表示されます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>タイムラインの自動作成</p>
            </div>
            <p>
              ポイントしてクリックするだけで、PagerDutyインシデント、Slackチャネル、Hipchatルームなどから、アクティビティと関連するタイムスタンプを自動的に事後検証のタイムラインに表示します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>簡単なエクスポート</p>
            </div>
            <p>
              事後検証を実施方法のステップバイステップの指示説明をカスタマイズし、事後検証の完了後、簡単にエクスポートして配布することができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>チーム調査</p>
            </div>
            <p>
              同僚からの更新をリアルタイムで確認しながら、チーム全体で一緒に事後検証を作成することができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>実用的な洞察</p>
            </div>
            <p>
              SMS、電子メール、電話、およびプッシュアラートによるアラートのボリュームと分布を視覚化します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>ツールチェーンの簡素化</p>
            </div>
            <p>
              PagerDutyはすべての適切な情報を集中管理しているため、コンテキストの切り替えが少なく、事後検証の情報をメンテナンスし、表面化させる際の手作業のオーバーヘッドを低減します。
            </p>
          </div>
        </div>
        <div className="conclusion">
          PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
          <br />
          インシデントをより迅速に解決するのに役立ちます。
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesPostMortems;
